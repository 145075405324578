import { memo } from 'react'

import Box, { BoxProps } from '@mui/material/Box'

// ----------------------------------------------------------------------

function ComingSoonIllustration({ ...other }: BoxProps) {
	return (
		<Box
			component="svg"
			width="100%"
			height="100%"
			viewBox="0 0 361 360"
			xmlns="http://www.w3.org/2000/svg"
			{...other}
		>
			<path
				d="M66.7507 271.037C129.391 305.172 230.911 305.179 293.551 271.037C356.191 236.894 356.191 181.541 293.551 147.398C230.911 113.256 129.391 113.256 66.7507 147.398C4.11074 181.541 4.11794 236.88 66.7507 271.037Z"
				fill="#F5F5F5"
			/>
			<path
				d="M100.138 258.646L143.778 283.846C150.877 287.942 162.39 287.942 169.489 283.846L276.452 222.091C283.551 217.994 283.551 211.349 276.452 207.245L232.813 182.045C225.706 177.948 214.194 177.948 207.094 182.045L100.138 243.799C93.032 247.896 93.032 254.542 100.138 258.646Z"
				fill="#EBEBEB"
			/>
			<path
				d="M271.219 214.661C270.772 212.292 269.966 208.426 267.165 205.366C264.133 202.13 260.558 199.45 256.603 197.446C252.671 195.444 250.526 190.051 257.971 190.246C264.839 190.447 271.37 198.166 272.14 204.732L271.219 214.661Z"
				fill="#37474F"
			/>
			<path
				d="M271.83 208.426C271.738 208.426 271.649 208.391 271.581 208.329C271.512 208.266 271.471 208.18 271.463 208.087C270.635 197.568 259.288 192.607 257.063 192.399C257.01 192.403 256.956 192.396 256.905 192.378C256.855 192.359 256.809 192.33 256.771 192.292C256.733 192.254 256.704 192.208 256.686 192.158C256.668 192.107 256.661 192.053 256.666 192C256.67 191.946 256.687 191.895 256.714 191.848C256.741 191.802 256.778 191.762 256.822 191.731C256.866 191.7 256.916 191.68 256.969 191.671C257.022 191.662 257.076 191.664 257.128 191.679C259.591 191.902 271.355 196.891 272.248 208.044C272.252 208.092 272.246 208.141 272.231 208.187C272.217 208.233 272.193 208.276 272.161 208.313C272.13 208.349 272.091 208.38 272.048 208.401C272.005 208.423 271.958 208.436 271.91 208.44L271.83 208.426Z"
				fill="white"
			/>
			<path
				d="M277.242 214.661C277.666 213.005 279.358 210.219 281.562 209.441C283.765 208.663 288.582 209.787 290.756 206.309C292.93 202.831 291.001 198.036 291.361 196.071C291.721 194.105 293.103 192.471 295.846 190.584C298.59 188.698 299.907 185.062 298.352 183.327C296.797 181.591 293.996 181.375 290.317 183.91C286.638 186.444 286.515 185.789 283.275 185.494C280.035 185.199 277.148 188.583 276.637 193.291L277.242 214.661Z"
				fill="#455A64"
			/>
			<path
				d="M277 206.33H276.913C276.866 206.32 276.821 206.301 276.782 206.273C276.742 206.245 276.709 206.21 276.683 206.169C276.658 206.128 276.641 206.082 276.633 206.034C276.626 205.987 276.628 205.938 276.64 205.891C278.994 195.473 290.874 185.839 295.208 184.205C295.304 184.17 295.409 184.175 295.501 184.219C295.593 184.262 295.664 184.34 295.698 184.435C295.732 184.531 295.727 184.636 295.684 184.728C295.641 184.819 295.563 184.89 295.468 184.925C291.241 186.523 279.628 195.912 277.36 206.086C277.334 206.159 277.285 206.223 277.22 206.267C277.155 206.311 277.078 206.333 277 206.33Z"
				fill="white"
			/>
			<path
				d="M282.549 195.617C282.475 195.617 282.403 195.595 282.342 195.554C282.28 195.513 282.232 195.455 282.204 195.387C281.037 192.507 281.261 188.691 281.268 188.525C281.295 188.448 281.347 188.382 281.415 188.337C281.484 188.292 281.565 188.271 281.647 188.276C281.729 188.282 281.806 188.314 281.868 188.368C281.929 188.422 281.971 188.495 281.988 188.576C281.988 188.576 281.765 192.399 282.873 195.106C282.892 195.151 282.901 195.2 282.901 195.249C282.9 195.298 282.89 195.346 282.871 195.391C282.851 195.436 282.823 195.477 282.787 195.511C282.752 195.544 282.71 195.571 282.665 195.588C282.628 195.605 282.589 195.615 282.549 195.617Z"
				fill="white"
			/>
			<path
				d="M288.15 202.86C286.523 202.86 282.347 202.637 279.078 200.492C278.996 200.438 278.938 200.354 278.918 200.258C278.898 200.162 278.917 200.062 278.97 199.98C279.023 199.898 279.107 199.841 279.203 199.82C279.299 199.8 279.399 199.819 279.481 199.872C283.333 202.4 288.733 202.112 288.791 202.104C288.84 202.101 288.889 202.108 288.935 202.125C288.981 202.141 289.023 202.166 289.059 202.199C289.096 202.232 289.125 202.272 289.146 202.316C289.166 202.36 289.178 202.408 289.18 202.457C289.183 202.505 289.176 202.554 289.16 202.599C289.144 202.645 289.12 202.687 289.088 202.723C289.055 202.759 289.017 202.789 288.973 202.81C288.929 202.831 288.882 202.843 288.834 202.846C288.748 202.853 288.503 202.86 288.15 202.86Z"
				fill="white"
			/>
			<path
				d="M153.62 282.557C152.785 279.554 149.962 276.962 145.29 274.27C140.617 271.577 132.618 268.106 134.49 263.405C136.362 258.703 144.505 262.49 147.55 265.147C151.539 268.632 154.628 273.787 154.714 277.337L153.62 282.557Z"
				fill="#37474F"
			/>
			<path
				d="M154.373 279.447C154.287 279.447 154.204 279.418 154.137 279.364C154.071 279.31 154.024 279.235 154.006 279.152C152.278 270.936 142.544 264.586 137.813 263.484C137.717 263.462 137.634 263.403 137.581 263.319C137.528 263.235 137.511 263.134 137.532 263.038C137.556 262.943 137.616 262.86 137.699 262.808C137.782 262.756 137.883 262.738 137.979 262.757C142.875 263.902 152.94 270.483 154.733 279C154.744 279.048 154.745 279.098 154.736 279.146C154.727 279.194 154.709 279.24 154.682 279.281C154.655 279.322 154.62 279.357 154.58 279.384C154.539 279.411 154.493 279.43 154.445 279.44L154.373 279.447Z"
				fill="white"
			/>
			<path
				d="M159.644 282.557C160.018 279.288 162.733 277.344 166.037 276.567C169.342 275.789 172.28 275.264 172.517 271.635C172.755 268.006 169.436 265.018 172.1 261.9C174.764 258.783 178.825 255.521 178.544 250.049C178.263 244.577 173.18 244.008 171.2 247.457C169.22 250.906 168.809 253.296 166.023 252.965C163.237 252.634 158.773 254.456 158.211 262.786L159.644 282.557Z"
				fill="#455A64"
			/>
			<path
				d="M159.148 275.962H159.097C159 275.947 158.913 275.896 158.853 275.817C158.794 275.739 158.768 275.641 158.78 275.544C159.882 267.386 165.599 260.424 169.775 255.341C171.568 253.181 173.116 251.273 173.785 249.89C173.805 249.846 173.834 249.807 173.87 249.774C173.905 249.741 173.947 249.715 173.992 249.698C174.038 249.682 174.086 249.674 174.135 249.676C174.183 249.678 174.231 249.69 174.275 249.71C174.363 249.754 174.431 249.83 174.465 249.923C174.498 250.015 174.495 250.117 174.455 250.207C173.735 251.647 172.172 253.591 170.344 255.816C166.225 260.856 160.588 267.689 159.544 275.645C159.531 275.738 159.483 275.824 159.409 275.883C159.335 275.942 159.242 275.97 159.148 275.962Z"
				fill="white"
			/>
			<path
				d="M163.591 264.298C163.515 264.299 163.44 264.276 163.378 264.232C163.316 264.188 163.269 264.125 163.245 264.053C162.587 261.763 162.274 259.387 162.316 257.004C162.32 256.906 162.362 256.812 162.433 256.744C162.504 256.675 162.599 256.637 162.698 256.637C162.747 256.638 162.795 256.649 162.84 256.669C162.884 256.688 162.924 256.717 162.958 256.752C162.992 256.788 163.018 256.83 163.035 256.876C163.052 256.921 163.06 256.97 163.058 257.019C163.02 259.313 163.318 261.601 163.943 263.808C163.96 263.854 163.968 263.903 163.965 263.952C163.963 264 163.95 264.048 163.929 264.092C163.907 264.136 163.877 264.175 163.84 264.206C163.803 264.238 163.76 264.262 163.713 264.276C163.674 264.291 163.632 264.298 163.591 264.298Z"
				fill="white"
			/>
			<path
				d="M168.695 271.44C165.976 271.287 163.3 270.689 160.775 269.669C160.73 269.652 160.688 269.626 160.653 269.593C160.617 269.56 160.589 269.52 160.569 269.476C160.55 269.431 160.54 269.383 160.539 269.335C160.539 269.287 160.548 269.238 160.566 269.194C160.583 269.148 160.609 269.106 160.642 269.071C160.675 269.035 160.715 269.007 160.759 268.987C160.803 268.966 160.851 268.955 160.899 268.954C160.948 268.952 160.996 268.96 161.041 268.978C163.486 269.963 166.072 270.552 168.702 270.72C168.751 270.721 168.799 270.732 168.844 270.751C168.889 270.771 168.929 270.8 168.962 270.835C168.996 270.871 169.022 270.913 169.039 270.958C169.056 271.004 169.064 271.053 169.062 271.102C169.057 271.195 169.015 271.282 168.947 271.345C168.878 271.408 168.788 271.442 168.695 271.44Z"
				fill="white"
			/>
			<path
				d="M101.045 228.593C101.218 228.521 101.765 216.965 96.783 210.154C91.8006 203.343 85.7886 202.558 81.8286 204.214C77.8686 205.87 78.675 209.938 83.5494 212.379C88.4238 214.819 94.4934 219.075 95.9406 226.851L101.045 228.593Z"
				fill="#37474F"
			/>
			<path
				d="M99.2159 225.202C99.1281 225.202 99.0432 225.17 98.9761 225.114C98.909 225.057 98.8639 224.979 98.8487 224.892C96.9839 213.156 90.0431 207.403 84.1751 205.935C84.1276 205.924 84.0828 205.903 84.0432 205.875C84.0037 205.846 83.9702 205.81 83.9447 205.769C83.9193 205.727 83.9023 205.681 83.8949 205.633C83.8875 205.585 83.8897 205.536 83.9015 205.488C83.911 205.435 83.9319 205.385 83.9628 205.341C83.9936 205.296 84.0336 205.259 84.0801 205.232C84.1266 205.205 84.1785 205.188 84.2322 205.182C84.2858 205.177 84.34 205.183 84.3911 205.2C90.4895 206.727 97.6967 212.667 99.6191 224.756C99.6273 224.804 99.6257 224.853 99.6145 224.901C99.6033 224.949 99.5827 224.994 99.5538 225.033C99.525 225.073 99.4885 225.106 99.4465 225.131C99.4046 225.157 99.358 225.173 99.3095 225.18L99.2159 225.202Z"
				fill="white"
			/>
			<path
				d="M100.152 250.049C99.4319 247.003 96.3287 245.009 92.7071 244.663C88.9415 244.325 84.5639 243.943 83.3975 240.775C82.2311 237.607 83.3975 233.295 82.8719 231.185C82.2311 228.629 81.2519 227.11 76.3055 225.555C71.8775 224.165 69.4295 219.931 73.8935 218.146C78.3575 216.36 82.8287 217.491 85.8671 218.866C88.9055 220.241 89.0351 221.659 93.3119 221.292C97.5887 220.925 100.778 223.611 101.045 226.203L100.152 250.049Z"
				fill="#455A64"
			/>
			<path
				d="M100.153 239.717C100.076 239.716 100.002 239.692 99.9389 239.649C99.876 239.605 99.8277 239.544 99.8002 239.472C96.2866 229.68 81.9154 220.817 77.1346 220.255C77.0378 220.243 76.9497 220.193 76.8891 220.116C76.8286 220.039 76.8004 219.942 76.8106 219.845C76.8215 219.747 76.8708 219.657 76.9476 219.595C77.0245 219.533 77.1228 219.504 77.221 219.514C82.1314 220.097 96.8986 229.183 100.499 239.22C100.516 239.266 100.524 239.315 100.522 239.364C100.52 239.412 100.508 239.46 100.487 239.505C100.466 239.549 100.437 239.589 100.4 239.621C100.364 239.654 100.322 239.679 100.275 239.695L100.153 239.717Z"
				fill="white"
			/>
			<path
				d="M96.7838 233.834H96.7046C96.6083 233.812 96.5248 233.753 96.4722 233.669C96.4196 233.585 96.4022 233.484 96.4238 233.388C97.6046 228.283 96.4238 225 96.4238 224.964C96.3903 224.868 96.3962 224.764 96.4401 224.673C96.484 224.581 96.5623 224.511 96.6578 224.478C96.7532 224.445 96.8581 224.45 96.9492 224.494C97.0404 224.538 97.1103 224.616 97.1438 224.712C97.1942 224.849 98.3894 228.226 97.1438 233.554C97.1231 233.634 97.0766 233.704 97.0115 233.755C96.9464 233.806 96.8663 233.834 96.7838 233.834Z"
				fill="white"
			/>
			<path
				d="M99.2154 237.6C99.1551 237.6 99.0957 237.585 99.0426 237.557C96.1626 235.994 89.8986 237.11 89.8338 237.118C89.7852 237.13 89.7345 237.133 89.6849 237.125C89.6353 237.117 89.5878 237.099 89.5454 237.072C89.5031 237.045 89.4666 237.01 89.4384 236.968C89.4102 236.927 89.3908 236.88 89.3815 236.831C89.3721 236.781 89.3729 236.731 89.3839 236.682C89.3949 236.633 89.4159 236.586 89.4454 236.546C89.475 236.505 89.5126 236.471 89.5559 236.446C89.5991 236.42 89.6472 236.404 89.697 236.398C89.9634 236.347 96.2778 235.224 99.3954 236.909C99.4797 236.942 99.5489 237.005 99.5903 237.086C99.6317 237.167 99.6423 237.26 99.6203 237.348C99.5984 237.436 99.5452 237.513 99.4707 237.564C99.3962 237.616 99.3055 237.639 99.2154 237.629V237.6Z"
				fill="white"
			/>
			<path
				d="M164.03 105.653C161.272 103.061 156.283 102.053 150.019 105.891C143.755 109.728 138.801 122.004 137.246 130.687C135.691 139.371 134.712 150.711 137.822 155.52C140.932 160.33 152.222 165.427 152.222 165.427L153.59 160.697L164.03 105.653Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.4"
				d="M164.03 105.653C161.272 103.061 156.283 102.053 150.019 105.891C143.755 109.728 138.801 122.004 137.246 130.687C135.691 139.371 134.712 150.711 137.822 155.52C140.932 160.33 152.222 165.427 152.222 165.427L153.59 160.697L164.03 105.653Z"
				fill="white"
			/>
			<path
				d="M140.918 154.08C145.807 159.934 153.619 160.726 153.619 160.726L183.751 143.28L171.684 136.318L140.918 154.08Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.2"
				d="M140.918 154.08C145.807 159.934 153.619 160.726 153.619 160.726L183.751 143.28L171.684 136.318L140.918 154.08Z"
				fill="black"
			/>
			<path
				d="M140.918 154.08C138.938 151.733 138.535 145.692 139.607 136.354C140.774 126.101 144.568 110.239 153.611 105.012C164.217 98.8921 174.988 114.099 171.705 136.354L140.918 154.08Z"
				fill="#054EF4"
			/>
			<path
				d="M211.505 203.703C217.071 210.01 221.146 211.263 222.636 212.609C224.127 213.955 223.537 216.864 220.21 217.793C216.884 218.722 213.147 218.175 210.807 216.166C208.467 214.157 206.732 211.414 204.384 209.988C202.037 208.563 200.871 208.224 200.396 206.388C199.92 204.552 201.188 198.828 201.188 198.828L211.505 203.703Z"
				fill="#263238"
			/>
			<path
				d="M208.317 193.68C208.022 196.143 207.662 198.31 209.534 201.111C210.139 202.011 210.797 202.874 211.507 203.695C214.761 207.447 217.403 210.37 217.871 210.895C218.339 211.421 215.956 213.329 213.004 212.335C210.052 211.342 207.28 207.295 205.221 204.898C204.307 203.825 203.414 202.853 202.708 202.104C201.759 201.106 201.209 199.794 201.16 198.418L201.023 194.53L208.317 193.68Z"
				fill="#FFA8A7"
			/>
			<path
				d="M194.608 214.02C200.174 220.334 204.242 221.58 205.74 222.926C207.237 224.273 206.64 227.189 203.313 228.118C199.987 229.046 196.25 228.499 193.903 226.483C191.556 224.467 189.835 221.738 187.488 220.313C185.14 218.887 183.967 218.549 183.499 216.713C183.031 214.877 184.291 209.153 184.291 209.153L194.608 214.02Z"
				fill="#263238"
			/>
			<path
				d="M191.411 204.005C191.123 206.474 190.756 208.634 192.635 211.435C193.238 212.337 193.897 213.201 194.608 214.02C197.862 217.778 200.497 220.702 200.965 221.22C201.433 221.738 199.057 223.654 196.098 222.66C193.139 221.666 190.374 217.62 188.322 215.23C187.408 214.15 186.515 213.185 185.809 212.436C184.861 211.437 184.311 210.126 184.261 208.75L184.117 204.854L191.411 204.005Z"
				fill="#FFA8A7"
			/>
			<path
				d="M168.907 70.1714H173.183C174.854 70.1695 176.508 70.4968 178.052 71.1345C179.596 71.7722 180.999 72.7078 182.181 73.888C183.363 75.0681 184.301 76.4696 184.942 78.0124C185.582 79.5552 185.912 81.209 185.913 82.8794V114.819H156.191V82.8794C156.193 79.5084 157.534 76.2761 159.918 73.893C162.303 71.51 165.536 70.1714 168.907 70.1714Z"
				fill="#263238"
			/>
			<path
				d="M187.697 107.28C182.722 102.053 180.18 101.52 177.156 101.52L182.916 118.44C182.916 118.44 192.391 124.704 197.935 128.16L201.456 120.398C201.456 120.398 192.672 112.486 187.697 107.28Z"
				fill="#FFA8A7"
			/>
			<path
				d="M154.072 145.44C152.222 149.429 149.838 153.67 152.25 157.774C154.662 161.878 174.254 157.774 174.254 157.774V145.44H154.072Z"
				fill="#263238"
			/>
			<path
				d="M175.271 101.52C177.431 101.34 179.879 101.405 182.471 105.768C185.063 110.131 186.517 112.579 185.697 118.93C184.876 125.28 181.809 130.176 181.585 132.552C181.362 134.928 181.931 146.347 181.931 146.347C181.931 146.347 178.144 151.755 168.726 152.007C165.95 152.067 163.195 151.51 160.66 150.374C158.126 149.238 155.877 147.553 154.074 145.44L161.274 132.523L159.431 106.272L166.465 102.06L175.271 101.52Z"
				fill="#054EF4"
			/>
			<path
				d="M175.271 101.52C177.431 101.34 179.879 101.405 182.471 105.768C185.063 110.131 186.517 112.579 185.697 118.93C184.876 125.28 181.809 130.176 181.585 132.552C181.362 134.928 181.931 146.347 181.931 146.347C181.931 146.347 178.144 151.755 168.726 152.007C165.95 152.067 163.195 151.51 160.66 150.374C158.126 149.238 155.877 147.553 154.074 145.44L161.274 132.523L159.431 106.272L166.465 102.06L175.271 101.52Z"
				fill="#E0E0E0"
			/>
			<path
				d="M166.472 110.808C165.79 110.808 165.115 110.674 164.484 110.412C163.854 110.151 163.281 109.768 162.799 109.285C162.317 108.802 161.935 108.229 161.674 107.599C161.414 106.968 161.28 106.292 161.281 105.61V90.4897H166.472V110.808Z"
				fill="#263238"
			/>
			<path
				d="M181.014 76.1763C182.577 77.1051 184.521 81.0507 184.326 89.3163C184.168 96.3291 181.893 98.0571 180.726 98.5611C179.56 99.0651 177.385 98.7555 175.24 98.3955V102.607C175.24 102.607 178.199 106.042 178.012 107.964C177.825 109.887 173.742 110.355 170.977 108.468C169.184 107.177 167.644 105.566 166.434 103.716V93.5427C166.434 93.5427 164.994 95.4723 162.633 93.9027C160.271 92.3331 160.177 88.4523 161.553 87.0627C162.928 85.6731 165.873 86.1051 166.434 88.0347C166.434 88.0347 169.847 88.1571 174.354 85.6659C176.133 84.7206 177.664 83.3683 178.821 81.7192C179.979 80.0701 180.73 78.1709 181.014 76.1763Z"
				fill="#FFA8A7"
			/>
			<path
				d="M176.5 87.5594C176.51 87.7924 176.428 88.02 176.271 88.1921C176.113 88.3643 175.894 88.4669 175.661 88.4774C175.428 88.4879 175.201 88.4054 175.028 88.2481C174.856 88.0908 174.754 87.8716 174.743 87.6386C174.738 87.5233 174.755 87.408 174.795 87.2995C174.834 87.1909 174.894 87.0911 174.972 87.0059C175.05 86.9207 175.144 86.8516 175.249 86.8026C175.353 86.7537 175.467 86.7258 175.582 86.7206C175.697 86.7154 175.812 86.733 175.921 86.7723C176.03 86.8117 176.129 86.872 176.215 86.9499C176.3 87.0278 176.369 87.1217 176.418 87.2263C176.467 87.3309 176.495 87.4441 176.5 87.5594Z"
				fill="#263238"
			/>
			<path
				d="M174.908 84.8592L173.079 85.9896C172.931 85.7392 172.885 85.4407 172.952 85.1573C173.019 84.8738 173.194 84.6276 173.439 84.4704C173.561 84.3974 173.696 84.3494 173.836 84.3294C173.976 84.3094 174.119 84.3178 174.256 84.3541C174.393 84.3903 174.521 84.4537 174.633 84.5405C174.745 84.6273 174.839 84.7357 174.908 84.8592Z"
				fill="#263238"
			/>
			<path
				d="M176.738 93.4995L178.783 94.1547C178.744 94.2904 178.679 94.4169 178.591 94.5269C178.502 94.6368 178.393 94.728 178.269 94.7951C178.145 94.8622 178.008 94.9038 177.868 94.9175C177.727 94.9311 177.586 94.9166 177.451 94.8747C177.18 94.7793 176.955 94.5839 176.822 94.3284C176.69 94.0728 176.66 93.7764 176.738 93.4995Z"
				fill="#F28F8F"
			/>
			<path
				d="M183.75 84.8522L181.965 83.6138C182.039 83.4929 182.138 83.3886 182.254 83.3073C182.37 83.226 182.502 83.1694 182.641 83.141C182.78 83.1127 182.923 83.1132 183.062 83.1425C183.201 83.1718 183.332 83.2293 183.448 83.3114C183.685 83.4813 183.849 83.7354 183.905 84.0218C183.961 84.3082 183.906 84.6052 183.75 84.8522Z"
				fill="#263238"
			/>
			<path
				d="M183.318 86.9185C183.321 87.0924 183.272 87.2632 183.178 87.4096C183.084 87.556 182.95 87.6716 182.791 87.7419C182.632 87.8122 182.455 87.8341 182.284 87.8049C182.112 87.7758 181.953 87.6968 181.827 87.5778C181.7 87.4589 181.611 87.3052 181.571 87.136C181.531 86.9668 181.541 86.7896 181.601 86.6264C181.661 86.4631 181.768 86.3212 181.908 86.2183C182.048 86.1153 182.216 86.0559 182.389 86.0474C182.507 86.0435 182.625 86.063 182.736 86.1047C182.847 86.1464 182.948 86.2095 183.034 86.2905C183.12 86.3714 183.19 86.4685 183.239 86.5762C183.287 86.684 183.314 86.8003 183.318 86.9185Z"
				fill="#263238"
			/>
			<path d="M179.156 86.4146L179.3 91.721L182.094 90.857L179.156 86.4146Z" fill="#F28F8F" />
			<path
				d="M175.271 98.3956C173.492 98.194 169.921 97.114 169.129 95.9116C169.282 96.6797 169.709 97.3657 170.331 97.8412C171.498 98.9572 175.271 99.7636 175.271 99.7636V98.3956Z"
				fill="#F28F8F"
			/>
			<path d="M156.63 218.146L99.3398 185.069L219.954 115.431L277.245 148.507L156.63 218.146Z" fill="#054EF4" />
			<path
				opacity="0.4"
				d="M156.63 218.146L99.3398 185.069L219.954 115.431L277.245 148.507L156.63 218.146Z"
				fill="white"
			/>
			<path
				d="M99.3398 185.069V251.222L102.357 252.965V207.698L153.621 237.29V282.557L156.63 284.299V218.146L99.3398 185.069Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.3"
				d="M99.3398 185.069V251.222L102.357 252.965V207.698L153.621 237.29V282.557L156.63 284.299V218.146L99.3398 185.069Z"
				fill="black"
			/>
			<path d="M102.355 252.965L105.372 251.223V209.441L102.355 207.699V252.965Z" fill="#054EF4" />
			<path opacity="0.5" d="M102.355 252.965L105.372 251.223V209.441L102.355 207.699V252.965Z" fill="black" />
			<path
				d="M156.629 284.299L159.646 282.557V237.291L274.227 171.137V216.403L277.243 214.661V148.507L156.629 218.146V284.299Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.1"
				d="M156.629 284.299L159.646 282.557V237.291L274.227 171.137V216.403L277.243 214.661V148.507L156.629 218.146V284.299Z"
				fill="black"
			/>
			<path d="M159.645 233.813V219.888L195.832 198.994V212.918L159.645 233.813Z" fill="#054EF4" />
			<path opacity="0.3" d="M159.645 233.813V219.888L195.832 198.994V212.918L159.645 233.813Z" fill="black" />
			<path d="M198.844 211.183V197.251L235.031 176.364V190.289L198.844 211.183Z" fill="#054EF4" />
			<path opacity="0.3" d="M198.844 211.183V197.251L235.031 176.364V190.289L198.844 211.183Z" fill="black" />
			<path d="M238.047 188.547V174.622L274.227 153.728V167.66L238.047 188.547Z" fill="#054EF4" />
			<path opacity="0.3" d="M238.047 188.547V174.622L274.227 153.728V167.66L238.047 188.547Z" fill="black" />
			<path
				d="M183.751 205.956V207.072C183.751 207.684 182.311 209.016 180.532 210.038L174.902 213.293C173.124 214.315 171.684 214.654 171.684 214.013V212.904L183.751 205.956Z"
				fill="#263238"
			/>
			<path
				d="M222.97 183.327V184.443C222.97 185.055 221.53 186.387 219.752 187.409L214.121 190.663C212.35 191.686 210.91 192.017 210.91 191.383V190.267L222.97 183.327Z"
				fill="#263238"
			/>
			<path
				d="M262.165 160.697V161.806C262.165 162.425 260.725 163.75 258.954 164.779L253.317 168.026C251.545 169.056 250.105 169.387 250.105 168.746V167.63L262.165 160.697Z"
				fill="#263238"
			/>
			<path d="M274.224 216.404L271.215 214.661V172.88L274.224 171.137V216.404Z" fill="#054EF4" />
			<path opacity="0.3" d="M274.224 216.404L271.215 214.661V172.88L274.224 171.137V216.404Z" fill="black" />
			<path
				d="M189.801 152.986V152.734C189.814 152.511 189.878 152.294 189.99 152.1C190.101 151.907 190.256 151.741 190.442 151.618L228.393 129.73C228.593 129.63 228.813 129.578 229.037 129.578C229.261 129.578 229.482 129.63 229.682 129.73L237.443 134.208C237.629 134.332 237.784 134.497 237.895 134.691C238.006 134.884 238.071 135.101 238.084 135.324V135.583C238.069 135.806 238.003 136.022 237.89 136.214C237.778 136.407 237.622 136.57 237.436 136.692L199.528 158.58C199.328 158.68 199.107 158.732 198.884 158.732C198.66 158.732 198.439 158.68 198.239 158.58L190.442 154.08C190.258 153.959 190.105 153.797 189.994 153.607C189.883 153.418 189.817 153.205 189.801 152.986Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M189.801 152.986V152.734C189.814 152.511 189.878 152.294 189.99 152.1C190.101 151.907 190.256 151.741 190.442 151.618L228.393 129.73C228.593 129.63 228.813 129.578 229.037 129.578C229.261 129.578 229.482 129.63 229.682 129.73L237.443 134.208C237.629 134.332 237.784 134.497 237.895 134.691C238.006 134.884 238.071 135.101 238.084 135.324V135.583C238.069 135.806 238.003 136.022 237.89 136.214C237.778 136.407 237.622 136.57 237.436 136.692L199.528 158.58C199.328 158.68 199.107 158.732 198.884 158.732C198.66 158.732 198.439 158.68 198.239 158.58L190.442 154.08C190.258 153.959 190.105 153.797 189.994 153.607C189.883 153.418 189.817 153.205 189.801 152.986Z"
				fill="white"
			/>
			<path
				d="M190.441 152.359L198.203 156.845C198.403 156.945 198.624 156.997 198.847 156.997C199.071 156.997 199.292 156.945 199.492 156.845L237.4 134.957C237.469 134.925 237.527 134.875 237.568 134.811C237.609 134.747 237.631 134.673 237.631 134.597C237.631 134.521 237.609 134.447 237.568 134.383C237.527 134.319 237.469 134.269 237.4 134.237L229.638 129.759C229.438 129.659 229.217 129.606 228.994 129.606C228.77 129.606 228.549 129.659 228.349 129.759L190.434 151.647C190.088 151.827 190.088 152.158 190.441 152.359Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.9"
				d="M190.441 152.359L198.203 156.845C198.403 156.945 198.624 156.997 198.847 156.997C199.071 156.997 199.292 156.945 199.492 156.845L237.4 134.957C237.469 134.925 237.527 134.875 237.568 134.811C237.609 134.747 237.631 134.673 237.631 134.597C237.631 134.521 237.609 134.447 237.568 134.383C237.527 134.319 237.469 134.269 237.4 134.237L229.638 129.759C229.438 129.659 229.217 129.606 228.994 129.606C228.77 129.606 228.549 129.659 228.349 129.759L190.434 151.647C190.088 151.827 190.088 152.158 190.441 152.359Z"
				fill="white"
			/>
			<path
				d="M142.2 173.83L165.031 187.02C165.231 187.12 165.452 187.172 165.675 187.172C165.899 187.172 166.12 187.12 166.32 187.02L183.124 177.315C183.196 177.285 183.257 177.236 183.3 177.172C183.343 177.107 183.366 177.032 183.366 176.955C183.366 176.877 183.343 176.802 183.3 176.738C183.257 176.674 183.196 176.624 183.124 176.595L160.286 163.411C160.088 163.309 159.868 163.255 159.645 163.255C159.422 163.255 159.202 163.309 159.004 163.411L142.2 173.088C141.84 173.297 141.84 173.628 142.2 173.83Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.8"
				d="M142.2 173.83L165.031 187.02C165.231 187.12 165.452 187.172 165.675 187.172C165.899 187.172 166.12 187.12 166.32 187.02L183.124 177.315C183.196 177.285 183.257 177.236 183.3 177.172C183.343 177.107 183.366 177.032 183.366 176.955C183.366 176.877 183.343 176.802 183.3 176.738C183.257 176.674 183.196 176.624 183.124 176.595L160.286 163.411C160.088 163.309 159.868 163.255 159.645 163.255C159.422 163.255 159.202 163.309 159.004 163.411L142.2 173.088C141.84 173.297 141.84 173.628 142.2 173.83Z"
				fill="white"
			/>
			<path
				d="M159.005 163.39L143.043 172.599L165.032 185.299C165.232 185.399 165.452 185.452 165.676 185.452C165.9 185.452 166.12 185.399 166.321 185.299L182.312 176.083L160.316 163.39C160.114 163.282 159.889 163.226 159.661 163.226C159.432 163.226 159.207 163.282 159.005 163.39Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M159.005 163.39L143.043 172.599L165.032 185.299C165.232 185.399 165.452 185.452 165.676 185.452C165.9 185.452 166.12 185.399 166.321 185.299L182.312 176.083L160.316 163.39C160.114 163.282 159.889 163.226 159.661 163.226C159.432 163.226 159.207 163.282 159.005 163.39Z"
				fill="white"
			/>
			<path
				d="M142.202 171.511L165.033 184.694C165.233 184.795 165.453 184.847 165.677 184.847C165.901 184.847 166.121 184.795 166.322 184.694L183.126 174.996C183.195 174.964 183.254 174.914 183.295 174.85C183.336 174.786 183.357 174.712 183.357 174.636C183.357 174.56 183.336 174.486 183.295 174.422C183.254 174.358 183.195 174.308 183.126 174.276L160.288 161.064C160.089 160.965 159.87 160.914 159.647 160.914C159.425 160.914 159.205 160.965 159.006 161.064L142.202 170.77C142.123 170.794 142.054 170.844 142.005 170.911C141.956 170.977 141.93 171.058 141.93 171.14C141.93 171.223 141.956 171.304 142.005 171.37C142.054 171.437 142.123 171.486 142.202 171.511Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.9"
				d="M142.202 171.511L165.033 184.694C165.233 184.795 165.453 184.847 165.677 184.847C165.901 184.847 166.121 184.795 166.322 184.694L183.126 174.996C183.195 174.964 183.254 174.914 183.295 174.85C183.336 174.786 183.357 174.712 183.357 174.636C183.357 174.56 183.336 174.486 183.295 174.422C183.254 174.358 183.195 174.308 183.126 174.276L160.288 161.064C160.089 160.965 159.87 160.914 159.647 160.914C159.425 160.914 159.205 160.965 159.006 161.064L142.202 170.77C142.123 170.794 142.054 170.844 142.005 170.911C141.956 170.977 141.93 171.058 141.93 171.14C141.93 171.223 141.956 171.304 142.005 171.37C142.054 171.437 142.123 171.486 142.202 171.511Z"
				fill="white"
			/>
			<path
				d="M154.856 157.774C154.1 159.833 155.259 161.496 157.455 161.482C158.609 161.422 159.723 161.038 160.669 160.374C161.615 159.711 162.355 158.795 162.805 157.731C163.568 155.671 162.402 154.008 160.206 154.023C159.052 154.083 157.938 154.466 156.992 155.13C156.046 155.793 155.306 156.709 154.856 157.774Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.1"
				d="M154.856 157.774C154.1 159.833 155.259 161.496 157.455 161.482C158.609 161.422 159.723 161.038 160.669 160.374C161.615 159.711 162.355 158.795 162.805 157.731C163.568 155.671 162.402 154.008 160.206 154.023C159.052 154.083 157.938 154.466 156.992 155.13C156.046 155.793 155.306 156.709 154.856 157.774Z"
				fill="black"
			/>
			<path
				d="M156.679 167.544C154.958 172.202 157.593 175.968 162.568 175.939C167.544 175.91 172.951 172.08 174.679 167.443C176.407 162.806 173.757 159.026 168.789 159.048C163.821 159.07 158.392 162.878 156.679 167.544Z"
				fill="#455A64"
			/>
			<path
				d="M160.713 154.08L157.732 156.161L154.751 158.242C153.765 162.151 153.47 168.257 157.134 173.513L174.285 161.532C170.584 156.24 164.694 154.469 160.713 154.08Z"
				fill="#455A64"
			/>
			<path
				d="M162.432 154.361C162.432 154.361 162.965 159.077 159.43 161.215C159.43 161.215 162.756 160.301 163.75 157.961C164.743 155.621 162.432 154.361 162.432 154.361Z"
				fill="#263238"
			/>
			<path
				d="M162.871 154.894C161.543 153.048 159.756 151.581 157.687 150.639L155.297 152.338L155.03 152.525L152.633 154.224C152.841 156.48 153.63 158.643 154.922 160.503C155.22 160.882 155.606 161.182 156.047 161.378C156.487 161.573 156.969 161.659 157.45 161.626C158.665 161.559 159.837 161.152 160.831 160.45C161.826 159.749 162.603 158.781 163.073 157.659C163.267 157.213 163.348 156.727 163.311 156.242C163.273 155.758 163.117 155.29 162.857 154.879L162.871 154.894Z"
				fill="#37474F"
			/>
			<path
				d="M152.711 152.943C152.567 153.232 152.504 153.556 152.529 153.878C152.554 154.201 152.665 154.511 152.852 154.775C153.039 155.039 153.294 155.248 153.59 155.379C153.885 155.51 154.211 155.559 154.532 155.52C155.336 155.478 156.111 155.209 156.768 154.745C157.425 154.282 157.938 153.642 158.248 152.899C158.391 152.61 158.454 152.288 158.429 151.966C158.405 151.644 158.294 151.334 158.108 151.07C157.922 150.806 157.668 150.597 157.373 150.466C157.079 150.334 156.754 150.284 156.433 150.322C155.63 150.369 154.856 150.639 154.199 151.102C153.541 151.565 153.026 152.202 152.711 152.943Z"
				fill="#455A64"
			/>
			<path
				d="M157.624 158.796C157.324 158.365 156.928 158.01 156.467 157.76C156.005 157.51 155.492 157.371 154.967 157.356C154.768 157.339 154.567 157.38 154.391 157.474C154.214 157.569 154.069 157.713 153.973 157.889L146.903 174.96C146.851 175.125 146.838 175.301 146.866 175.472C146.893 175.643 146.96 175.806 147.061 175.946C147.361 176.379 147.758 176.735 148.221 176.985C148.684 177.235 149.199 177.373 149.725 177.386C149.925 177.405 150.126 177.364 150.302 177.269C150.479 177.175 150.624 177.03 150.719 176.854L157.775 159.84C157.842 159.668 157.863 159.481 157.837 159.298C157.81 159.115 157.737 158.942 157.624 158.796Z"
				fill="#455A64"
			/>
			<path
				d="M166.038 200.607C166.038 200.364 166.012 200.123 165.959 199.887C165.353 197.125 163.725 194.694 161.401 193.083H151.861C149.536 194.693 147.905 197.124 147.297 199.887C147.246 200.123 147.222 200.365 147.225 200.607C147.222 200.65 147.222 200.693 147.225 200.736C147.311 202.054 148.218 203.357 149.961 204.336C153.64 206.496 159.594 206.496 163.273 204.336C165.016 203.328 165.923 202.025 166.009 200.736C166.021 200.694 166.031 200.65 166.038 200.607Z"
				fill="#455A64"
			/>
			<path
				d="M156.628 199.21C160.159 199.21 163.022 197.556 163.022 195.516C163.022 193.476 160.159 191.823 156.628 191.823C153.097 191.823 150.234 193.476 150.234 195.516C150.234 197.556 153.097 199.21 156.628 199.21Z"
				fill="#37474F"
			/>
			<path
				d="M155.116 194.638C154.932 194.699 154.772 194.816 154.659 194.973C154.545 195.13 154.484 195.319 154.484 195.513C154.484 195.706 154.545 195.895 154.659 196.052C154.772 196.209 154.932 196.326 155.116 196.387C155.586 196.625 156.105 196.749 156.632 196.749C157.158 196.749 157.677 196.625 158.147 196.387C158.331 196.326 158.491 196.209 158.604 196.052C158.718 195.895 158.779 195.706 158.779 195.513C158.779 195.319 158.718 195.13 158.604 194.973C158.491 194.816 158.331 194.699 158.147 194.638C157.677 194.4 157.158 194.276 156.632 194.276C156.105 194.276 155.586 194.4 155.116 194.638Z"
				fill="#455A64"
			/>
			<path
				d="M146.362 177.948C146.178 178.009 146.018 178.127 145.905 178.284C145.791 178.441 145.73 178.629 145.73 178.823C145.73 179.017 145.791 179.206 145.905 179.363C146.018 179.52 146.178 179.637 146.362 179.698C146.832 179.936 147.351 180.06 147.878 180.06C148.404 180.06 148.924 179.936 149.393 179.698C149.577 179.637 149.737 179.52 149.851 179.363C149.964 179.206 150.025 179.017 150.025 178.823C150.025 178.629 149.964 178.441 149.851 178.284C149.737 178.127 149.577 178.009 149.393 177.948C148.924 177.71 148.404 177.586 147.878 177.586C147.351 177.586 146.832 177.71 146.362 177.948Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.3"
				d="M146.362 177.948C146.178 178.009 146.018 178.127 145.905 178.284C145.791 178.441 145.73 178.629 145.73 178.823C145.73 179.017 145.791 179.206 145.905 179.363C146.018 179.52 146.178 179.637 146.362 179.698C146.832 179.936 147.351 180.06 147.878 180.06C148.404 180.06 148.924 179.936 149.393 179.698C149.577 179.637 149.737 179.52 149.851 179.363C149.964 179.206 150.025 179.017 150.025 178.823C150.025 178.629 149.964 178.441 149.851 178.284C149.737 178.127 149.577 178.009 149.393 177.948C148.924 177.71 148.404 177.586 147.878 177.586C147.351 177.586 146.832 177.71 146.362 177.948Z"
				fill="black"
			/>
			<path
				d="M149.938 178.474L158.686 195.163L154.575 195.869L145.812 179.158L149.938 178.474Z"
				fill="#455A64"
			/>
			<path
				d="M146.853 173.614C146.582 173.547 146.298 173.561 146.034 173.653C145.771 173.745 145.54 173.911 145.37 174.132C144.876 174.688 144.52 175.353 144.331 176.072C144.142 176.792 144.125 177.545 144.282 178.272C144.326 178.511 144.425 178.736 144.571 178.93C144.717 179.124 144.907 179.281 145.125 179.388L148.394 180.612L150.021 174.78L146.853 173.614Z"
				fill="#37474F"
			/>
			<path
				d="M148.595 175.342C148.099 175.898 147.742 176.564 147.551 177.284C147.361 178.005 147.344 178.76 147.5 179.489C147.86 180.807 148.94 181.087 149.891 180.115C150.386 179.56 150.744 178.896 150.934 178.177C151.124 177.457 151.142 176.703 150.985 175.975C150.632 174.658 149.552 174.377 148.595 175.342Z"
				fill="#263238"
			/>
			<path
				d="M132.155 189.807C135.193 186.711 136.971 178.971 134.012 176.516H118.95C115.991 178.971 117.769 186.704 120.807 189.807L120.887 189.893L120.995 189.994C121.081 190.081 121.172 190.163 121.268 190.239L121.34 190.296L121.571 190.469C121.695 190.561 121.825 190.645 121.959 190.721C123.362 191.431 124.912 191.801 126.485 191.801C128.057 191.801 129.607 191.431 131.01 190.721C131.139 190.649 131.269 190.563 131.406 190.469L131.615 190.304L131.708 190.239L131.967 190.001L132.09 189.879L132.155 189.807Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M132.155 189.807C135.193 186.711 136.971 178.971 134.012 176.516H118.95C115.991 178.971 117.769 186.704 120.807 189.807L120.887 189.893L120.995 189.994C121.081 190.081 121.172 190.163 121.268 190.239L121.34 190.296L121.571 190.469C121.695 190.561 121.825 190.645 121.959 190.721C123.362 191.431 124.912 191.801 126.485 191.801C128.057 191.801 129.607 191.431 131.01 190.721C131.139 190.649 131.269 190.563 131.406 190.469L131.615 190.304L131.708 190.239L131.967 190.001L132.09 189.879L132.155 189.807Z"
				fill="white"
			/>
			<path
				d="M126.474 184.205C131.337 184.205 135.279 181.929 135.279 179.122C135.279 176.314 131.337 174.039 126.474 174.039C121.61 174.039 117.668 176.314 117.668 179.122C117.668 181.929 121.61 184.205 126.474 184.205Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.9"
				d="M126.474 184.205C131.337 184.205 135.279 181.929 135.279 179.122C135.279 176.314 131.337 174.039 126.474 174.039C121.61 174.039 117.668 176.314 117.668 179.122C117.668 181.929 121.61 184.205 126.474 184.205Z"
				fill="white"
			/>
			<path
				d="M122.687 176.933C120.599 178.143 120.599 180.101 122.687 181.311C123.863 181.904 125.161 182.213 126.478 182.213C127.795 182.213 129.093 181.904 130.269 181.311C132.357 180.101 132.357 178.143 130.269 176.933C129.094 176.337 127.795 176.026 126.478 176.026C125.161 176.026 123.862 176.337 122.687 176.933Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M122.687 176.933C120.599 178.143 120.599 180.101 122.687 181.311C123.863 181.904 125.161 182.213 126.478 182.213C127.795 182.213 129.093 181.904 130.269 181.311C132.357 180.101 132.357 178.143 130.269 176.933C129.094 176.337 127.795 176.026 126.478 176.026C125.161 176.026 123.862 176.337 122.687 176.933Z"
				fill="white"
			/>
			<path
				d="M130.268 179.006C129.092 178.413 127.794 178.104 126.477 178.104C125.16 178.104 123.862 178.413 122.686 179.006C122.183 179.285 121.753 179.68 121.434 180.158C121.753 180.637 122.183 181.032 122.686 181.31C123.862 181.904 125.16 182.212 126.477 182.212C127.794 182.212 129.092 181.904 130.268 181.31C130.772 181.032 131.201 180.637 131.521 180.158C131.201 179.68 130.772 179.285 130.268 179.006Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.3"
				d="M130.268 179.006C129.092 178.413 127.794 178.104 126.477 178.104C125.16 178.104 123.862 178.413 122.686 179.006C122.183 179.285 121.753 179.68 121.434 180.158C121.753 180.637 122.183 181.032 122.686 181.31C123.862 181.904 125.16 182.212 126.477 182.212C127.794 182.212 129.092 181.904 130.268 181.31C130.772 181.032 131.201 180.637 131.521 180.158C131.201 179.68 130.772 179.285 130.268 179.006Z"
				fill="black"
			/>
			<path
				d="M127.842 180.634C130.024 179.271 131.671 177.199 132.508 174.766C133.897 170.705 133.076 165.794 131.831 163.764C130.146 161.014 126.071 161.323 127.273 165.924C128.476 170.525 129.224 175.5 127.316 178.826L127.842 180.634Z"
				fill="#37474F"
			/>
			<path
				d="M127.987 179.798C127.942 179.798 127.899 179.783 127.864 179.755C127.847 179.738 127.833 179.717 127.823 179.694C127.814 179.671 127.809 179.647 127.809 179.622C127.809 179.597 127.814 179.573 127.823 179.55C127.833 179.527 127.847 179.506 127.864 179.489C129.082 178.143 130.013 176.564 130.602 174.847C131.191 173.131 131.426 171.312 131.291 169.502C131.083 166.169 129.851 163.742 129.383 163.375C129.35 163.339 129.331 163.291 129.331 163.242C129.331 163.192 129.35 163.145 129.383 163.109C129.418 163.075 129.465 163.057 129.513 163.057C129.561 163.057 129.608 163.075 129.643 163.109C130.262 163.642 131.471 166.234 131.673 169.488C131.812 171.349 131.572 173.218 130.967 174.983C130.361 176.748 129.404 178.371 128.152 179.755C128.131 179.776 128.104 179.791 128.075 179.799C128.046 179.806 128.016 179.806 127.987 179.798Z"
				fill="white"
			/>
			<path
				d="M124.331 180.634C124.215 180.232 123.96 179.885 123.611 179.654C123.181 179.415 122.728 179.219 122.258 179.071C121.444 178.776 120.407 178.351 120.299 177.372C120.274 177.021 120.318 176.669 120.429 176.335C120.629 175.883 120.677 175.377 120.566 174.895C120.386 174.463 119.925 174.233 119.529 173.974C119.092 173.716 118.72 173.361 118.442 172.937C118.207 172.56 118.094 172.12 118.118 171.677C118.197 170.77 119.068 170.172 119.097 169.272C119.133 167.954 116.051 166.169 117.693 164.952C119.795 163.361 123.086 165.204 124.029 166.558C124.972 167.911 124.972 168.516 126.693 169.661C128.133 170.611 129.803 170.806 129.803 172.678C129.803 174.55 127.406 174.636 129.292 176.76C130.43 178.034 128.572 179.136 127.852 180.648C126.678 180.634 125.49 180.576 124.331 180.634Z"
				fill="#455A64"
			/>
			<path
				d="M126.23 180.8C126.206 180.799 126.182 180.793 126.159 180.783C126.137 180.772 126.117 180.758 126.1 180.74C126.083 180.722 126.07 180.701 126.062 180.677C126.053 180.654 126.049 180.63 126.05 180.605C126.468 169.726 120.931 165.91 119.822 165.608C119.777 165.592 119.739 165.56 119.717 165.518C119.695 165.475 119.689 165.426 119.702 165.38C119.715 165.334 119.745 165.294 119.786 165.27C119.827 165.245 119.876 165.237 119.923 165.248C121.795 165.759 126.814 170.352 126.403 180.62C126.401 180.666 126.383 180.709 126.351 180.743C126.319 180.776 126.276 180.796 126.23 180.8Z"
				fill="white"
			/>
			<path
				d="M125.352 173.067C125.308 173.067 125.264 173.051 125.23 173.023C123.624 171.583 120.485 171.641 120.449 171.641C120.4 171.641 120.354 171.622 120.319 171.589C120.284 171.555 120.264 171.509 120.262 171.461C120.262 171.412 120.28 171.365 120.313 171.328C120.347 171.292 120.393 171.27 120.442 171.267C120.579 171.267 123.761 171.231 125.482 172.707C125.509 172.732 125.528 172.765 125.536 172.801C125.545 172.837 125.542 172.875 125.529 172.91C125.516 172.944 125.493 172.974 125.463 172.996C125.432 173.018 125.396 173.03 125.359 173.031L125.352 173.067Z"
				fill="white"
			/>
			<path
				d="M253.275 143.18L255.982 110.319C256.01 109.787 255.9 109.257 255.663 108.78C255.426 108.303 255.069 107.896 254.628 107.597C254.141 107.361 253.604 107.244 253.063 107.254C252.521 107.264 251.989 107.401 251.511 107.655L215.511 128.434C215.035 128.742 214.631 129.149 214.326 129.628C214.022 130.106 213.824 130.644 213.747 131.206L211.039 164.067C211.011 164.599 211.121 165.129 211.358 165.606C211.595 166.082 211.952 166.49 212.393 166.788C212.881 167.024 213.417 167.142 213.959 167.132C214.5 167.122 215.032 166.985 215.511 166.731L251.511 145.952C251.986 145.643 252.389 145.235 252.694 144.757C252.998 144.279 253.196 143.741 253.275 143.18Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.8"
				d="M253.275 143.18L255.982 110.319C256.01 109.787 255.9 109.257 255.663 108.78C255.426 108.303 255.069 107.896 254.628 107.597C254.141 107.361 253.604 107.244 253.063 107.254C252.521 107.264 251.989 107.401 251.511 107.655L215.511 128.434C215.035 128.742 214.631 129.149 214.326 129.628C214.022 130.106 213.824 130.644 213.747 131.206L211.039 164.067C211.011 164.599 211.121 165.129 211.358 165.606C211.595 166.082 211.952 166.49 212.393 166.788C212.881 167.024 213.417 167.142 213.959 167.132C214.5 167.122 215.032 166.985 215.511 166.731L251.511 145.952C251.986 145.643 252.389 145.235 252.694 144.757C252.998 144.279 253.196 143.741 253.275 143.18Z"
				fill="white"
			/>
			<path
				d="M253.274 143.18L255.981 110.319C256.009 109.787 255.899 109.257 255.662 108.78C255.425 108.303 255.068 107.896 254.627 107.597C254.139 107.361 253.603 107.244 253.061 107.254C252.52 107.264 251.988 107.401 251.51 107.655L215.51 128.434C215.031 128.73 214.63 129.135 214.336 129.615L219.93 132.84V164.16L251.488 145.937C251.966 145.633 252.373 145.229 252.681 144.753C252.99 144.277 253.191 143.741 253.274 143.18Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.9"
				d="M253.274 143.18L255.981 110.319C256.009 109.787 255.899 109.257 255.662 108.78C255.425 108.303 255.068 107.896 254.627 107.597C254.139 107.361 253.603 107.244 253.061 107.254C252.52 107.264 251.988 107.401 251.51 107.655L215.51 128.434C215.031 128.73 214.63 129.135 214.336 129.615L219.93 132.84V164.16L251.488 145.937C251.966 145.633 252.373 145.229 252.681 144.753C252.99 144.277 253.191 143.741 253.274 143.18Z"
				fill="white"
			/>
			<path
				d="M214.078 165.809L216.785 132.948C216.864 132.387 217.061 131.848 217.364 131.369C217.667 130.89 218.069 130.481 218.542 130.169L254.542 109.397C255.435 108.886 256.083 109.296 255.982 110.319L253.275 143.179C253.197 143.74 253 144.278 252.697 144.756C252.394 145.234 251.992 145.642 251.518 145.951L215.518 166.731C214.64 167.242 213.992 166.831 214.078 165.809Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M214.078 165.809L216.785 132.948C216.864 132.387 217.061 131.848 217.364 131.369C217.667 130.89 218.069 130.481 218.542 130.169L254.542 109.397C255.435 108.886 256.083 109.296 255.982 110.319L253.275 143.179C253.197 143.74 253 144.278 252.697 144.756C252.394 145.234 251.992 145.642 251.518 145.951L215.518 166.731C214.64 167.242 213.992 166.831 214.078 165.809Z"
				fill="white"
			/>
			<path
				d="M232.646 139.442C232.744 138.559 233.03 137.707 233.484 136.943C233.939 136.179 234.552 135.522 235.281 135.014C236.599 134.251 237.549 135.014 237.441 136.685C237.343 137.568 237.056 138.42 236.602 139.183C236.148 139.947 235.535 140.605 234.806 141.113C233.431 141.84 232.509 141.12 232.646 139.442Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.4"
				d="M232.646 139.442C232.744 138.559 233.03 137.707 233.484 136.943C233.939 136.179 234.552 135.522 235.281 135.014C236.599 134.251 237.549 135.014 237.441 136.685C237.343 137.568 237.056 138.42 236.602 139.183C236.148 139.947 235.535 140.605 234.806 141.113C233.431 141.84 232.509 141.12 232.646 139.442Z"
				fill="white"
			/>
			<path d="M232.013 150.25L244.073 143.287L242.568 142.416L230.508 149.378L232.013 150.25Z" fill="#054EF4" />
			<path
				opacity="0.9"
				d="M232.013 150.25L244.073 143.287L242.568 142.416L230.508 149.378L232.013 150.25Z"
				fill="white"
			/>
			<path
				d="M246.44 159.322L235.64 165.543C235.419 165.661 235.17 165.716 234.92 165.701C234.717 165.692 234.518 165.641 234.337 165.55L229.592 162.814C229.412 162.689 229.262 162.526 229.155 162.335C229.048 162.144 228.985 161.931 228.973 161.712V161.46C228.973 161.05 229.261 160.877 229.613 161.086L232.493 162.735L233.134 163.109L232.99 162.389L230.499 149.429L232.004 150.3L244.071 143.28L246.951 158.213C246.985 158.428 246.954 158.647 246.863 158.845C246.772 159.042 246.625 159.208 246.44 159.322Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M246.44 159.322L235.64 165.543C235.419 165.661 235.17 165.716 234.92 165.701C234.717 165.692 234.518 165.641 234.337 165.55L229.592 162.814C229.412 162.689 229.262 162.526 229.155 162.335C229.048 162.144 228.985 161.931 228.973 161.712V161.46C228.973 161.05 229.261 160.877 229.613 161.086L232.493 162.735L233.134 163.109L232.99 162.389L230.499 149.429L232.004 150.3L244.071 143.28L246.951 158.213C246.985 158.428 246.954 158.647 246.863 158.845C246.772 159.042 246.625 159.208 246.44 159.322Z"
				fill="white"
			/>
			<path d="M229.199 161.014L232.871 158.89L233.62 163.836L229.199 161.014Z" fill="#054EF4" />
			<path opacity="0.7" d="M229.199 161.014L232.871 158.89L233.62 163.836L229.199 161.014Z" fill="white" />
			<path
				d="M231.992 150.25L230.487 149.378L232.979 162.338C233.065 162.734 232.835 162.9 232.482 162.691L229.602 161.042C229.249 160.834 228.961 161.042 228.961 161.417V161.669C228.974 161.887 229.036 162.1 229.143 162.291C229.251 162.482 229.4 162.646 229.58 162.77L234.325 165.506C234.506 165.597 234.705 165.649 234.908 165.658L231.992 150.25Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.8"
				d="M231.992 150.25L230.487 149.378L232.979 162.338C233.065 162.734 232.835 162.9 232.482 162.691L229.602 161.042C229.249 160.834 228.961 161.042 228.961 161.417V161.669C228.974 161.887 229.036 162.1 229.143 162.291C229.251 162.482 229.4 162.646 229.58 162.77L234.325 165.506C234.506 165.597 234.705 165.649 234.908 165.658L231.992 150.25Z"
				fill="white"
			/>
			<path
				d="M193.113 167.659C195.323 167.494 196.036 166.68 196.396 165.701C196.756 164.722 196.885 163.649 196.029 161.381C195.172 159.113 190.269 156.139 185.178 157.226C180.088 158.314 179.382 162.202 179.656 163.246C179.929 164.29 182.989 166.939 186.957 167.393C188.997 167.659 191.057 167.748 193.113 167.659Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M193.113 167.659C195.323 167.494 196.036 166.68 196.396 165.701C196.756 164.722 196.885 163.649 196.029 161.381C195.172 159.113 190.269 156.139 185.178 157.226C180.088 158.314 179.382 162.202 179.656 163.246C179.929 164.29 182.989 166.939 186.957 167.393C188.997 167.659 191.057 167.748 193.113 167.659Z"
				fill="white"
			/>
			<path
				d="M190.528 160.56C190.82 160.33 191.181 160.204 191.554 160.204C191.926 160.204 192.287 160.33 192.58 160.56C193.253 160.942 193.791 161.523 194.12 162.223C194.344 162.807 194.236 163.325 193.984 163.325C193.732 163.325 193.544 162.792 192.58 161.986C191.615 161.179 190.312 160.863 190.528 160.56Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.9"
				d="M190.528 160.56C190.82 160.33 191.181 160.204 191.554 160.204C191.926 160.204 192.287 160.33 192.58 160.56C193.253 160.942 193.791 161.523 194.12 162.223C194.344 162.807 194.236 163.325 193.984 163.325C193.732 163.325 193.544 162.792 192.58 161.986C191.615 161.179 190.312 160.863 190.528 160.56Z"
				fill="white"
			/>
			<path
				d="M159.428 106.287C157.052 107.885 154.237 109.779 154.626 116.014C155.015 122.249 159.666 139.45 159.983 141.545C160.3 143.64 172.943 153.785 175.268 155.787C177.94 158.112 178.948 158.602 180.157 159.934C181.367 161.266 182.504 164.664 183.757 166.14C185.723 168.423 189.956 168.473 191.908 168.207C193.859 167.94 192.628 165.571 191.908 163.887C191.188 162.202 189.258 159.905 189.805 159.509C190.18 159.243 192.217 159.603 193.794 160.949C195.011 161.986 195.155 163.001 195.839 163.476C196.984 164.283 197.365 162.857 196.184 160.596C195.004 158.335 193.204 156.715 191.425 156.154C192.21 156.216 192.995 156.04 193.679 155.65C194.658 155.045 195.004 153.677 193.794 154.037C193.074 154.246 192.354 154.037 190.914 154.037C186.392 154.037 185.032 155.679 179.797 150.624C174.563 145.57 167.557 138.694 167.557 138.694C167.557 138.694 165.203 124.366 164.144 118.023C163.244 112.558 162.87 108.979 159.428 106.287Z"
				fill="#FFA8A7"
			/>
			<path
				d="M201.456 120.377L210.391 101.456L215.431 103.536C215.431 103.536 208.023 125.957 206.957 128.391C206.331 129.831 204.322 131.58 201.413 130.162C200.779 129.852 199.519 129.111 197.957 128.139"
				fill="#FFA8A7"
			/>
			<path
				d="M213.539 109.174C214.158 107.323 216.124 105.35 216.419 104.566C216.681 103.946 216.768 103.266 216.671 102.6L214.374 102.831L213.539 109.174Z"
				fill="#FFA8A7"
			/>
			<path
				d="M208.8 104.76C209.174 103.903 209.239 100.901 208.8 99.1728C208.36 97.4448 209.419 95.2848 209.152 94.068C208.886 92.8512 209.664 92.484 210.369 92.9232C210.801 93.1974 211.159 93.5743 211.41 94.0203C211.661 94.4662 211.799 94.9674 211.809 95.4792C211.845 96.7392 211.924 99.9432 211.413 101.506C210.902 103.068 208.8 104.76 208.8 104.76Z"
				fill="#FFA8A7"
			/>
			<path
				d="M209.989 101.815C210.126 102.204 210.644 102.744 212.322 102.456L215.41 103.536L216.67 103.464C215.997 103.941 215.173 104.155 214.352 104.068C213.532 103.98 212.772 103.597 212.214 102.989C212.214 102.989 209.946 103.27 209.989 101.815Z"
				fill="#F28F8F"
			/>
			<path
				d="M218.626 100.548C216.689 102.06 216.963 104.026 219.245 104.947C220.511 105.382 221.86 105.512 223.185 105.327C224.51 105.142 225.773 104.646 226.87 103.882C228.807 102.37 228.526 100.397 226.251 99.4752C224.985 99.0433 223.635 98.9153 222.311 99.1017C220.986 99.2881 219.724 99.7835 218.626 100.548Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M218.626 100.548C216.689 102.06 216.963 104.026 219.245 104.947C220.511 105.382 221.86 105.512 223.185 105.327C224.51 105.142 225.773 104.646 226.87 103.882C228.807 102.37 228.526 100.397 226.251 99.4752C224.985 99.0433 223.635 98.9153 222.311 99.1017C220.986 99.2881 219.724 99.7835 218.626 100.548Z"
				fill="white"
			/>
			<path
				d="M228.219 89.5107L221.113 90.5043L213.992 91.4979C214.151 96.1059 215.713 101.268 217.751 103.896L228.003 102.456C229.249 99.3603 229.328 93.9819 228.219 89.5107Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M228.219 89.5107L221.113 90.5043L213.992 91.4979C214.151 96.1059 215.713 101.268 217.751 103.896L228.003 102.456C229.249 99.3603 229.328 93.9819 228.219 89.5107Z"
				fill="white"
			/>
			<path
				d="M215.82 89.345C213.214 90.9362 213.487 92.8945 216.432 93.7081C219.856 94.4578 223.435 93.9464 226.512 92.2681C229.119 90.6697 228.845 88.7186 225.9 87.8978C222.476 87.151 218.896 87.6648 215.82 89.345Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.9"
				d="M215.82 89.345C213.214 90.9362 213.487 92.8945 216.432 93.7081C219.856 94.4578 223.435 93.9464 226.512 92.2681C229.119 90.6697 228.845 88.7186 225.9 87.8978C222.476 87.151 218.896 87.6648 215.82 89.345Z"
				fill="white"
			/>
			<path
				d="M216.871 90.0001C214.711 91.1017 214.92 92.3761 217.267 92.8801C220.025 93.2976 222.845 92.9023 225.381 91.7425C227.505 90.6409 227.333 89.3665 224.978 88.8625C222.223 88.4456 219.405 88.8409 216.871 90.0001Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.4"
				d="M216.871 90.0001C214.711 91.1017 214.92 92.3761 217.267 92.8801C220.025 93.2976 222.845 92.9023 225.381 91.7425C227.505 90.6409 227.333 89.3665 224.978 88.8625C222.223 88.4456 219.405 88.8409 216.871 90.0001Z"
				fill="white"
			/>
			<path
				d="M217.138 91.685C216.756 91.8723 216.404 92.115 216.094 92.405C216.472 92.5988 216.877 92.7347 217.296 92.8082C220.054 93.2257 222.874 92.8304 225.411 91.6706C225.794 91.4863 226.146 91.2433 226.455 90.9506C226.076 90.7568 225.671 90.6209 225.252 90.5474C222.494 90.1299 219.674 90.5252 217.138 91.685Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.3"
				d="M217.138 91.685C216.756 91.8723 216.404 92.115 216.094 92.405C216.472 92.5988 216.877 92.7347 217.296 92.8082C220.054 93.2257 222.874 92.8304 225.411 91.6706C225.794 91.4863 226.146 91.2433 226.455 90.9506C226.076 90.7568 225.671 90.6209 225.252 90.5474C222.494 90.1299 219.674 90.5252 217.138 91.685Z"
				fill="black"
			/>
			<path
				d="M215.626 103.68C215.028 103.769 214.419 103.74 213.833 103.594C213.027 103.378 212.263 102.6 211.507 101.211C210.874 100.044 209.787 97.4524 210.247 96.0196C210.838 94.198 212.947 93.9388 214.351 93.766L214.834 93.7012L215.143 95.9116L214.625 95.9764C213.833 96.07 212.515 96.2356 212.364 96.6964C212.163 97.5892 213.675 101.067 214.445 101.448C215.14 101.545 215.847 101.429 216.475 101.117L217.483 103.104C216.898 103.392 216.271 103.587 215.626 103.68Z"
				fill="#054EF4"
			/>
			<path
				opacity="0.7"
				d="M215.626 103.68C215.028 103.769 214.419 103.74 213.833 103.594C213.027 103.378 212.263 102.6 211.507 101.211C210.874 100.044 209.787 97.4524 210.247 96.0196C210.838 94.198 212.947 93.9388 214.351 93.766L214.834 93.7012L215.143 95.9116L214.625 95.9764C213.833 96.07 212.515 96.2356 212.364 96.6964C212.163 97.5892 213.675 101.067 214.445 101.448C215.14 101.545 215.847 101.429 216.475 101.117L217.483 103.104C216.898 103.392 216.271 103.587 215.626 103.68Z"
				fill="white"
			/>
			<path
				d="M212.983 96.7036C211.608 96.7036 207.569 98.3092 207.482 99.7636C207.396 101.218 210.139 101.11 210.139 101.11C210.01 101.326 209.96 101.581 210 101.83C210.039 102.078 210.165 102.305 210.355 102.471C211.039 103.061 216.223 101.261 216.223 101.261C216.042 100.667 215.767 100.106 215.41 99.598C215.151 99.2697 214.773 99.0574 214.358 99.0076C214.358 99.0076 215.028 96.7036 212.983 96.7036Z"
				fill="#FFA8A7"
			/>
			<path d="M110.104 167.609L104.07 164.131L110.104 162.389V167.609Z" fill="#E0E0E0" />
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path d="M110.104 162.389V167.609L104.07 164.131" fill="#E0E0E0" />
			</g>
			<path d="M73.9171 183.283V188.827L40.7539 169.682V164.131L73.9171 183.283Z" fill="#E0E0E0" />
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path d="M73.9171 183.283V188.827L40.7539 169.682V164.131L73.9171 183.283Z" fill="#E0E0E0" />
			</g>
			<path d="M37.7383 120.607L70.9087 139.759V185.018L37.7383 165.873V120.607Z" fill="#E0E0E0" />
			<path d="M70.9102 139.759L116.133 113.645V158.904L70.9102 185.018V139.759Z" fill="#EBEBEB" />
			<path
				d="M116.132 113.645L82.9687 94.4927L37.7383 120.607L70.9087 139.759L116.132 113.645Z"
				fill="#F5F5F5"
			/>
			<path d="M73.918 141.502L113.122 118.865V129.312L73.918 151.942V141.502Z" fill="#E0E0E0" />
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path
					d="M88.9955 133.661C88.9955 134.143 90.4355 133.704 92.2139 132.682L97.8443 129.427C99.6227 128.405 101.055 127.181 101.055 126.698V125.827L88.9883 132.797L88.9955 133.661Z"
					fill="#E0E0E0"
				/>
			</g>
			<path d="M73.918 155.426L113.122 132.797V143.237L73.918 165.874V155.426Z" fill="#E0E0E0" />
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path
					d="M88.9955 147.6C88.9955 148.082 90.4355 147.6 92.2139 146.613L97.8443 143.359C99.6227 142.337 101.055 141.113 101.055 140.637V139.766L88.9883 146.729L88.9955 147.6Z"
					fill="#E0E0E0"
				/>
			</g>
			<path d="M73.918 169.351L113.122 146.722V157.169L73.918 179.798V169.351Z" fill="#E0E0E0" />
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path
					d="M88.9955 161.518C88.9955 162 90.4355 161.561 92.2139 160.531L97.8443 157.284C99.6227 156.254 101.055 155.038 101.055 154.555V153.684L88.9883 160.646L88.9955 161.518Z"
					fill="#E0E0E0"
				/>
			</g>
			<path d="M73.918 188.827L76.9348 187.092V181.541L73.918 183.283V188.827Z" fill="#E0E0E0" />
			<path d="M110.105 167.609L113.122 165.874V160.646L110.105 162.389V167.609Z" fill="#E0E0E0" />
			<path
				d="M57.1266 122.494C60.165 119.391 61.9506 111.694 58.9842 109.195H43.9146C40.9554 111.658 42.7338 119.391 45.7722 122.487L45.8514 122.573L45.9594 122.674C46.0456 122.761 46.1369 122.843 46.233 122.919L46.305 122.976L46.5354 123.149C46.66 123.24 46.7898 123.325 46.9242 123.401C48.3271 124.111 49.8772 124.481 51.4494 124.481C53.0217 124.481 54.5718 124.111 55.9746 123.401C56.1114 123.325 56.2437 123.241 56.3706 123.149L56.5794 122.991L56.673 122.919L56.9394 122.681L57.0546 122.566L57.1266 122.494Z"
				fill="#EBEBEB"
			/>
			<path
				d="M45.2257 108.209C41.7841 110.196 41.7841 113.414 45.2257 115.409C48.6673 117.403 54.2401 117.396 57.6745 115.409C61.1089 113.421 61.1161 110.21 57.6745 108.209C54.2329 106.207 48.6601 106.221 45.2257 108.209Z"
				fill="#F5F5F5"
			/>
			<path
				d="M47.6754 109.612C45.5874 110.822 45.5874 112.78 47.6754 113.99C48.85 114.586 50.1488 114.897 51.4662 114.897C52.7835 114.897 54.0823 114.586 55.257 113.99C57.345 112.78 57.345 110.822 55.257 109.612C54.0813 109.019 52.7829 108.71 51.4662 108.71C50.1494 108.71 48.851 109.019 47.6754 109.612Z"
				fill="#E0E0E0"
			/>
			<path
				d="M55.2407 111.693C54.066 111.097 52.7672 110.786 51.4499 110.786C50.1325 110.786 48.8337 111.097 47.6591 111.693C47.1537 111.966 46.7234 112.359 46.4062 112.838C46.7229 113.319 47.1532 113.715 47.6591 113.99C48.8337 114.587 50.1325 114.897 51.4499 114.897C52.7672 114.897 54.066 114.587 55.2407 113.99C55.7465 113.715 56.1768 113.319 56.4935 112.838C56.1763 112.359 55.746 111.966 55.2407 111.693Z"
				fill="#E0E0E0"
			/>
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path
					d="M55.2407 111.693C54.066 111.097 52.7672 110.786 51.4499 110.786C50.1325 110.786 48.8337 111.097 47.6591 111.693C47.1537 111.966 46.7234 112.359 46.4062 112.838C46.7229 113.319 47.1532 113.715 47.6591 113.99C48.8337 114.587 50.1325 114.897 51.4499 114.897C52.7672 114.897 54.066 114.587 55.2407 113.99C55.7465 113.715 56.1768 113.319 56.4935 112.838C56.1763 112.359 55.746 111.966 55.2407 111.693Z"
					fill="#E0E0E0"
				/>
			</g>
			<path
				d="M48.1293 112.838C47.057 111.751 46.1344 110.526 45.3861 109.195C44.1189 106.963 43.8885 103.961 44.5437 103.594C45.1989 103.226 47.8557 106.214 47.8557 106.214C47.6136 104.955 47.5194 103.672 47.5749 102.391C47.6901 100.397 48.1725 98.5896 48.7485 98.2224C49.3245 97.8552 50.5917 100.382 51.1389 102.197C51.6861 104.011 51.9957 105.516 51.9957 105.516C52.2162 104.094 52.5435 102.691 52.9749 101.318C53.6373 99.432 54.2421 99 54.7101 99.1584C55.1781 99.3168 55.4877 100.231 55.6245 102.758C55.6723 103.96 55.6338 105.163 55.5093 106.358C55.5093 106.358 56.4525 105.322 57.4893 104.321C58.6341 103.219 59.1237 103.068 59.4549 103.277C59.7861 103.486 59.8725 105.199 58.1013 108.403C57.2184 110.035 56.0891 111.522 54.7533 112.81C54.7533 112.81 52.3197 114.422 48.1293 112.838Z"
				fill="#EBEBEB"
			/>
			<path
				d="M87.5182 126.223L105.518 115.783C105.871 115.581 105.871 115.25 105.518 115.063L80.7934 100.98C80.5934 100.88 80.3727 100.828 80.149 100.828C79.9253 100.828 79.7047 100.88 79.5046 100.98L61.663 111.297C61.3102 111.506 61.3102 111.837 61.663 112.017L86.2294 126.201C86.4278 126.305 86.6476 126.361 86.8713 126.365C87.095 126.368 87.3165 126.32 87.5182 126.223Z"
				fill="#E0E0E0"
			/>
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path
					d="M87.5182 126.223L105.518 115.783C105.871 115.581 105.871 115.25 105.518 115.063L80.7934 100.98C80.5934 100.88 80.3727 100.828 80.149 100.828C79.9253 100.828 79.7047 100.88 79.5046 100.98L61.663 111.297C61.3102 111.506 61.3102 111.837 61.663 112.017L86.2294 126.201C86.4278 126.305 86.6476 126.361 86.8713 126.365C87.095 126.368 87.3165 126.32 87.5182 126.223Z"
					fill="#E0E0E0"
				/>
			</g>
			<path
				d="M61.6483 111.276L81.9379 98.3733C82.1346 98.2705 82.3532 98.2168 82.5751 98.2168C82.797 98.2168 83.0157 98.2705 83.2123 98.3733L90.5203 102.593C90.6913 102.696 90.8172 102.86 90.8731 103.052C90.9291 103.244 90.9111 103.45 90.8227 103.629L90.5275 104.205C90.4391 104.385 90.4211 104.591 90.4771 104.783C90.5331 104.974 90.659 105.138 90.8299 105.242L105.554 113.76C105.632 113.79 105.699 113.844 105.747 113.913C105.794 113.983 105.819 114.065 105.819 114.149C105.819 114.232 105.794 114.314 105.747 114.384C105.699 114.453 105.632 114.507 105.554 114.537L87.4819 126.173C87.2877 126.279 87.0698 126.335 86.8483 126.335C86.6268 126.335 86.4089 126.279 86.2147 126.173L61.6483 111.989C61.5855 111.953 61.5332 111.901 61.4969 111.838C61.4605 111.776 61.4414 111.705 61.4414 111.632C61.4414 111.56 61.4605 111.489 61.4969 111.426C61.5332 111.363 61.5855 111.312 61.6483 111.276Z"
				fill="#EBEBEB"
			/>
			<path
				d="M316.709 141.898C321.936 136.563 325.01 114.847 319.905 110.614H293.985C288.881 114.84 291.955 136.534 297.182 141.891L297.319 142.035L297.499 142.215C297.65 142.359 297.809 142.495 297.974 142.632L298.097 142.733C298.226 142.834 298.356 142.935 298.493 143.028C298.709 143.186 298.935 143.331 299.169 143.46C303.489 145.944 310.445 145.944 314.75 143.46C314.985 143.331 315.211 143.186 315.427 143.028L315.794 142.747L315.953 142.625C316.111 142.495 316.262 142.359 316.406 142.222L316.615 142.02L316.709 141.898Z"
				fill="#37474F"
			/>
			<path
				d="M296.21 108.907C290.284 112.327 290.284 117.871 296.21 121.291C302.135 124.711 311.733 124.704 317.651 121.291C323.57 117.878 323.57 112.327 317.651 108.907C311.733 105.487 302.128 105.487 296.21 108.907Z"
				fill="#455A64"
			/>
			<path
				d="M299.052 110.549C294.733 113.062 294.733 117.13 299.052 119.642C303.372 122.155 310.457 122.155 314.799 119.642C319.14 117.13 319.119 113.062 314.799 110.549C310.479 108.036 303.401 108.043 299.052 110.549Z"
				fill="#37474F"
			/>
			<path
				d="M314.794 114.869C310.474 112.356 303.397 112.356 299.048 114.869C297.997 115.438 297.102 116.256 296.441 117.252C297.102 118.25 297.997 119.071 299.048 119.643C303.368 122.155 310.445 122.155 314.794 119.643C315.848 119.075 316.744 118.253 317.401 117.252C316.743 116.254 315.847 115.435 314.794 114.869Z"
				fill="#E0E0E0"
			/>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					d="M314.794 114.869C310.474 112.356 303.397 112.356 299.048 114.869C297.997 115.438 297.102 116.256 296.441 117.252C297.102 118.25 297.997 119.071 299.048 119.643C303.368 122.155 310.445 122.155 314.794 119.643C315.848 119.075 316.744 118.253 317.401 117.252C316.743 116.254 315.847 115.435 314.794 114.869Z"
					fill="#263238"
				/>
			</g>
			<path
				d="M311.784 117.266C313.044 113.45 314.8 106.466 321.331 101.232C328.027 95.8463 334.629 91.5839 332.354 88.0487C330.281 84.8375 324.045 84.3623 316.917 89.8631C309.998 95.1911 306.873 107.417 307.485 115.524L311.784 117.266Z"
				fill="#054EF4"
			/>
			<g opacity="0.1">
				<path
					d="M311.784 117.266C313.044 113.45 314.8 106.466 321.331 101.232C328.027 95.8463 334.629 91.5839 332.354 88.0487C330.281 84.8375 324.045 84.3623 316.917 89.8631C309.998 95.1911 306.873 107.417 307.485 115.524L311.784 117.266Z"
					fill="black"
				/>
			</g>
			<path
				d="M308.602 116.352H308.551C308.503 116.347 308.456 116.332 308.414 116.308C308.372 116.284 308.335 116.252 308.305 116.214C308.276 116.176 308.254 116.132 308.242 116.085C308.23 116.038 308.227 115.989 308.234 115.942C310.711 96.7823 322.634 88.3007 328.459 88.0199C328.508 88.0149 328.557 88.0201 328.604 88.0351C328.65 88.0501 328.693 88.0747 328.73 88.1072C328.766 88.1397 328.796 88.1794 328.816 88.2239C328.836 88.2684 328.847 88.3166 328.848 88.3655C328.852 88.4644 328.817 88.5609 328.752 88.6348C328.686 88.7087 328.594 88.7541 328.495 88.7615C324.175 88.9631 311.59 95.8175 308.969 116.035C308.956 116.124 308.912 116.204 308.845 116.263C308.777 116.321 308.691 116.353 308.602 116.352Z"
				fill="white"
			/>
			<path
				d="M302.258 118.159C302.089 117.085 301.664 116.066 301.02 115.189C300.376 114.313 299.532 113.603 298.557 113.119C296.433 111.967 293.849 113.119 291.357 109.642C288.866 106.164 291.048 103.342 290.637 101.657C290.227 99.972 289.795 98.8704 286.958 97.6608C284.121 96.4512 283.358 94.6728 284.337 90.684C285.317 86.6952 285.266 85.4856 282.84 83.736C280.413 81.9864 280.298 79.7688 282.84 78.3072C285.381 76.8456 289.903 76.8672 293.481 80.0424C297.06 83.2176 297.513 84.5856 301.452 84.9672C305.39 85.3488 307.644 85.5072 308.537 87.8472C309.429 90.1872 308.969 92.0016 308.537 94.1904C308.334 95.1895 308.47 96.2278 308.924 97.1408C309.378 98.0537 310.123 98.7891 311.042 99.2304C312.763 100.044 315.629 100.67 316.694 103.81C317.76 106.949 315.398 113.35 312.374 117.281C312.338 117.266 311.618 119.563 302.258 118.159Z"
				fill="#054EF4"
			/>
			<path
				d="M308.848 118.526C308.799 118.526 308.75 118.516 308.705 118.498C308.66 118.479 308.62 118.451 308.585 118.416C308.551 118.381 308.524 118.34 308.506 118.295C308.488 118.249 308.479 118.201 308.48 118.152C308.364 112.009 306.994 105.955 304.456 100.361C300.402 91.6126 287.449 81.2806 286.657 80.8054C286.612 80.7911 286.571 80.7676 286.536 80.7365C286.501 80.7054 286.473 80.6672 286.453 80.6246C286.433 80.5819 286.423 80.5356 286.422 80.4887C286.421 80.4417 286.43 80.3951 286.448 80.3518C286.487 80.2581 286.56 80.1827 286.652 80.1412C286.745 80.0997 286.85 80.0952 286.945 80.1286C287.392 80.2726 300.863 90.835 305.125 100.044C307.707 105.744 309.101 111.91 309.222 118.166C309.222 118.215 309.212 118.263 309.193 118.307C309.174 118.352 309.146 118.392 309.112 118.426C309.077 118.46 309.035 118.486 308.99 118.503C308.944 118.52 308.896 118.528 308.848 118.526Z"
				fill="white"
			/>
			<path
				d="M300.279 93.7082C300.219 93.7085 300.16 93.6936 300.107 93.665C297.392 92.1746 290.905 93.233 290.84 93.2474C290.743 93.2601 290.645 93.235 290.566 93.1774C290.487 93.1198 290.433 93.034 290.416 92.9378C290.407 92.89 290.409 92.8411 290.419 92.7938C290.43 92.7466 290.45 92.7019 290.478 92.6624C290.506 92.6229 290.542 92.5894 290.583 92.5637C290.624 92.5381 290.67 92.5209 290.718 92.513C290.991 92.4698 297.529 91.4042 300.459 93.017C300.502 93.0403 300.54 93.0719 300.571 93.11C300.601 93.1481 300.624 93.1919 300.637 93.2388C300.651 93.2857 300.655 93.3348 300.649 93.3833C300.643 93.4317 300.628 93.4786 300.604 93.521C300.572 93.5789 300.525 93.627 300.468 93.66C300.411 93.693 300.345 93.7097 300.279 93.7082Z"
				fill="white"
			/>
			<path
				d="M308.178 111.6C308.088 111.61 307.997 111.587 307.923 111.535C307.848 111.483 307.795 111.406 307.773 111.318C307.751 111.23 307.762 111.138 307.803 111.057C307.844 110.976 307.914 110.913 307.998 110.88C311.051 109.224 313.383 104.544 313.405 104.501C313.422 104.451 313.45 104.405 313.486 104.367C313.523 104.328 313.567 104.298 313.615 104.278C313.664 104.258 313.717 104.248 313.77 104.25C313.822 104.251 313.874 104.264 313.922 104.287C313.969 104.31 314.011 104.343 314.045 104.383C314.079 104.423 314.104 104.471 314.119 104.521C314.133 104.572 314.137 104.625 314.129 104.678C314.121 104.73 314.103 104.78 314.075 104.825C313.974 105.026 311.605 109.764 308.351 111.535C308.3 111.571 308.24 111.594 308.178 111.6Z"
				fill="white"
			/>
		</Box>
	)
}

export default memo(ComingSoonIllustration)
