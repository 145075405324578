export { default as AvatarShape } from './avatar-shape'

export { default as SeoIllustration } from './seo-illustration'
export { default as UploadIllustration } from './upload-illustration'
export { default as BookingIllustration } from './booking-illustration'
export { default as CheckInIllustration } from './check-in-illustration'
export { default as CheckoutIllustration } from './check-out-illustration'
export { default as ForbiddenIllustration } from './ForbiddenIllustration'
export { default as MotivationIllustration } from './motivation-illustration'
export { default as SeverErrorIllustration } from './SeverErrorIllustration'
export { default as ComingSoonIllustration } from './coming-soon-illustration'
export { default as MaintenanceIllustration } from './MaintenanceIllustration'
export { default as PageNotFoundIllustration } from './PageNotFoundIllustration'
export { default as OrderCompleteIllustration } from './order-complete-illustration'
export { default as UpgradeStorageIllustration } from './upgrade-storage-illustration'
export { default as HomeContactIllustration } from './HomeContactIllustration'
